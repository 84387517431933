import axios from "axios";
import Cookies from 'js-cookie';
import { MessageBox, Message, Notification } from "element-ui";
import cache from './cache.js'
import router from '../router/index.js'
// 分割url
function GetRequest() {
    var fullURL = window.location.href;
	//debugger
    //var url = location.search; //获取url中"?"符后的字串
    var theRequest = new Object();
    let strs;
    if (fullURL.indexOf("?") != -1) {
        var str = fullURL.slice(fullURL.indexOf("?")+1);
	//	debugger
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}
let params = GetRequest();
 
//1. 创建axios实例,配置基础路径
const service = axios.create({
    baseURL:process.env.VUE_APP_BASE_API,
    timeout:20000,//连接超时
	//timeout:1000,//连接超时
});
 
//2. 定义请求拦截器:给所有请求都带上token
service.interceptors.request.use((config)=>{
   // config.headers.accessToken = Cookies.get('token')
   //如果没有token需要传sid=account
  //    if(Cookies.get('token')){
		// config.headers = {
		// 	'accessToken':cache.session.getItem('token'),
		// }
	 // }else{
		// config.headers = {
		// 	'accessToken':cache.session.getItem('token'),
		// 	'sid':'account',
		// 	'apiKey':cache.session.get('apiKey')
		// }
	 // }
	 config.headers = {
	 	'accessToken':cache.session.get('token'),
	 	'sid':'account',
	 	'apiKey':cache.session.get('apiKey')
	 }
	 
	if ((config.method === 'post' || config.method === 'put')) {
	  const requestObj = {
	    url: config.url,
	    data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
	    time: new Date().getTime()
	  }
	  const sessionObj = cache.session.getJSON('sessionObj')
	  if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
	    cache.session.setJSON('sessionObj', requestObj)
	  } else {
	    const s_url = sessionObj.url;                  // 请求地址
	    const s_data = sessionObj.data;                // 请求数据
	    const s_time = sessionObj.time;                // 请求时间
	    const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
	    if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {	
	      return Promise.reject(new Error('duplicate submit'))
	    } else {
	      cache.session.setJSON('sessionObj', requestObj)
	    }
	  }
	}  
	console.log(config.url,'config.url');
	
    return config;
},(error)=>{//返回错误的信息
    return Promise.reject(error);
});
 
//3. 定义响应拦截器: 对服务器响应客户端的数据进行统一处理
service.interceptors.response.use(response=>{
    //3.1 对响应数据进行处理
    let data = response.data;
    let code = data.code;
    if (code !== "0") {
		let urlParam =  GetRequest()
		if(urlParam && urlParam.mod == '1' && code =='-1000'){
			cache.session.remove('token')
			router.push({ path: '/giftLogin'});
		}else{
			Message({
			    showClose: true,
			    message: data.msg,
			    type: 'error'
			});
		}
        return Promise.resolve(data);
    }else {
        return data;
    }
},(error)=>{
	console.log(error)
	let data = {}
	if(error.message=='duplicate submit'){
		data = {
			code: '-1',
			msg:''
		}
	}else{
		
		Message({
		    showClose: true,
		    message: error.message,
		    type: 'error'
		  });
		  data = {
		  	code: error.code,
		  	msg:error.message 
		  }
	}
		 
    return Promise.resolve(data);
})
 
//4. 导出axios实例,供组件异步请求使用
export default service;